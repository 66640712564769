import React from 'react';

import Layout, { Col2Small, Content, PageSection } from '../components/layout';
import Seo from '../components/seo';
import { ContactSection } from '../components/contact';
import {
    PageHeaderTitle,
    PageSubtitle,
    PageText,
    PageTitle,
    TextLink,
} from '../components/typography';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { SecondaryButton } from '../components/buttons';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { RandomReveal } from 'react-random-reveal';

function Index({ data, location }) {
    const { t } = useTranslation();
    return (
        <Layout
            location={location}
            bgImage={{
                file: 'chip',
                opacity: 0.1,
            }}
            headerContent={<Header />}
        >
            <Seo title="Home" />
            <IntroSection />

            <TeaserSection
                title={t(
                    'teaser_platform_title',
                    'Digitale Welt sicher machen'
                )}
                description={t(
                    'teaser_platform_subtitle',
                    'Mit unserer zentralen Plattform und den darauf aufbauenden Services und Dienstleistungen.'
                )}
                url="/about/"
                image={
                    <StaticImage
                        src="../images/condignum-infinity.png"
                        alt="Condignum Kreisel"
                        className="block max-w-sm"
                        objectFit="cover"
                        placeholder="none"
                        height={384}
                        quality={100}
                        formats={['auto', 'png']}
                    />
                }
                imageIsLeft={false}
                isDark
            />

            <TeaserSection
                title={t('teaser_vuln_title', 'Vulnerability Management')}
                description={t(
                    'teaser_vuln_subtitle',
                    'Überblick bewahren und Schwachstellen effizient behandeln.'
                )}
                url="/vulnerability-management/"
                image={
                    <StaticImage
                        src="../images/headers/vulnerability.jpg"
                        alt="Security-Rating"
                        objectFit="cover"
                        placeholder="none"
                        height={300}
                    />
                }
                imageIsLeft
                isDark={false}
            />

            <TeaserSection
                title={t('teaser_testing_title', 'Security Testing')}
                description={t(
                    'teaser_testing_subtitle',
                    'Vertrauen ist gut, Kontrolle ist besser. Durch systematische Sicherheitsanalysen von IT-Systemen, Netzwerken und Anwendungen können Schwachstellen identifiziert und deren Ausnutzung proaktiv verhindert werden.'
                )}
                url="/security-testing/"
                image={
                    <StaticImage
                        src="../images/headers/audit.png"
                        alt="Security-Rating"
                        objectFit="cover"
                        placeholder="none"
                        height={300}
                    />
                }
                imageIsLeft={false}
                isDark
            />

            <ContactSection requestDemo={false} isDark={false}></ContactSection>
        </Layout>
    );
}

function Header() {
    const { t } = useTranslation();

    return (
        <Content
            size="big"
            hasPadding={false}
            className="pt-16 pb-12 px-6 md:px-8 md:pb-16"
        >
            <div className="grid md:grid-cols-2 items-start">
                <div className="max-w-[30rem] relative">
                    <PageHeaderTitle className="font-bold">
                        <span className="block overflow-hidden h-[3.5em] vs:h-[2.4em]">
                            <RandomReveal
                                isPlaying
                                duration={0.8}
                                revealEasing="linear"
                                ignoreCharacterSet={[
                                    'C',
                                    'Y',
                                    'B',
                                    'E',
                                    'R',
                                    '-',
                                ]}
                                characters={t('headerTitle')}
                            />
                        </span>
                        <span className="sr-only">{t('headerTitle')}</span>
                    </PageHeaderTitle>
                    <PageText className="mt-6">
                        <PageText inline type="highlighted">
                            condignum{' '}
                        </PageText>
                        <PageText inline type="dimmed">
                            <Trans i18nKey="headerCondignum1">
                                (aus dem Lateinischen für “angemessen”)
                            </Trans>
                        </PageText>{' '}
                        <Trans i18nKey="headerCondignum2">
                            ist&nbsp;ein&nbsp;österreichisches
                            Cyber-Security-Unternehmen mit dem Ansporn, die
                            digitale Welt ein großes Stück sicherer
                            zu&nbsp;machen.
                        </Trans>
                    </PageText>
                </div>
                <StaticImage
                    src="../images/homepage_header.png"
                    width={540}
                    objectFit="contain"
                    placeholder="none"
                    quality={100}
                    alt="appropriate steps to ensure Information security"
                    className="absolute-important -mb-[35%] md:static-important -z-1 opacity-20 md:opacity-100 md:translate-y-0 md:mb-0"
                />
            </div>
        </Content>
    );
}

function IntroSection() {
    return (
        <>
            <PageSection size="big" className="pb-4">
                <header>
                    <PageTitle>
                        <Trans i18nKey="rating_title">Das Rating</Trans>
                    </PageTitle>
                    <PageSubtitle hasMargin={false}>
                        <Trans i18nKey="rating_subtitle">
                            Tausende Events - ein einfaches Rating
                        </Trans>
                    </PageSubtitle>
                </header>
                <div className="md:flex items-center gap-4 mt-3 bg-light-grey">
                    <StaticImage
                        src="../images/screens/rating.png"
                        alt="Security Rating"
                        objectFit="cover"
                        placeholder="none"
                        height={400}
                    />
                    <div className="px-4 max-w-lg md:max-w-[16em] md:px-0 py-4">
                        <PageText>
                            <Trans i18nKey="rating_p1">
                                Analog zu einem Rating der Kreditwürdigkeit
                                bewertet das condignum{' '}
                                <TextLink to="/security-rating/">
                                    Cyber Security Rating
                                </TextLink>{' '}
                                Ihre aktuelle Security Posture auf einer
                                einfachen Skala von A bis E.
                            </Trans>
                        </PageText>
                        <PageText>
                            <Trans i18nKey="rating_p2">
                                Feststellung des aktuellen Sicherheitsniveaus
                                mittels angepassten Fragebogen und
                                automatisiertem technischen Assessment.
                            </Trans>
                        </PageText>
                        <SecondaryButton
                            to="https://app.condignum.com/rating"
                            className="mt-4 block mr-4"
                        >
                            <Trans i18nKey="start">Rating starten</Trans>
                        </SecondaryButton>
                    </div>
                </div>
            </PageSection>
        </>
    );
}

function TeaserSection({
    title,
    description,
    url,
    image,
    isDark,
    imageIsLeft = true,
    isCentered = true,
}) {
    return (
        <PageSection
            size="big"
            isDark={isDark}
            className={`${!isCentered ? 'md:py-6' : ''}`}
        >
            <Col2Small className={`${isCentered ? 'items-center' : ''}`}>
                <span className="max-w-md">{image}</span>
                <div
                    className={`flex flex-col ${
                        imageIsLeft ? '' : 'sm:order-first'
                    }`}
                >
                    <span>
                        <PageTitle hasMargin={false} className="mt-4 sm:mt-0">
                            {title}
                        </PageTitle>
                        <PageText inline>{description}</PageText>
                    </span>
                    <SecondaryButton
                        to={url}
                        className="self-start mt-2 sm:mt-6"
                    >
                        <Trans i18nKey="more">Mehr erfahren</Trans>
                    </SecondaryButton>
                </div>
            </Col2Small>
        </PageSection>
    );
}

export default Index;

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
